import { Injectable } from '@angular/core';
import { BaseApiService } from '../base-api.service';
import { CarsubsAcessosResponse } from './carsubs-acessos.models';

@Injectable({
  providedIn: 'root',
})
export class CarsubsAcessosService extends BaseApiService {
  constructor() {
    super('carsubs-acessos');
  }

  getCarsubsAcessos(rotaAtual: string) {
    return this.get<CarsubsAcessosResponse>(undefined, {
      search: `CarsubsAcessos.Router=${rotaAtual ? rotaAtual : null}`,
    });
  }
}
