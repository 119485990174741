import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { OrganizacoesService } from '@app/api/organizacoes/organizacoes.service';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

export const OrganizacaoGuard = () => {
  const router = inject(Router);
  const organizacoesService: OrganizacoesService = inject(OrganizacoesService);
  const rotaAtual = location.pathname.split('/')[1];

  return organizacoesService.getOrganizacao(rotaAtual).pipe(
    map((organizacao) => {
      if (!organizacao) {
        return router.navigate(['/']);
      }
      return true;
    }),
    catchError((error) => {
      console.error(error);
      return of(false);
    })
  );
};
