import { Injectable } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private readonly toast: HotToastService) {}

  hotToastService = this.toast;

  show(message: string) {
    this.toast.show(message);
  }

  showError(message: string) {
    this.toast.show(message, {
      icon: undefined,
      className: 'info error',
      position: 'bottom-center',
      duration: 5000,
      dismissible: true,
    });
  }

  showSuccess(message: string) {
    this.toast.show(message, {
      icon: undefined,
      className: 'info success',
      position: 'bottom-center',
      dismissible: true,
      duration: 3000,
    });
  }

  showWarning(message: string, time: number = 4000) {
    this.toast.show(message, {
      icon: undefined,
      className: 'info warning',
      position: 'bottom-center',
      dismissible: true,
      duration: time,
    });
  }

  showInfo(message: string) {
    this.toast.info(message);
  }
}
