// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  BASE_API_URL: 'https://apilmmobilidade.locaviaweb.com.br/carsubs/api-locaviaweb/v1/',
  encryptSecretKey:
    'JNQg$HPvP_,?!1Oe<rQ5D5i}iy:n)@INFO`Qv<E_$.]BL5HJdR-BI22}yn5p)z9_ao?7',
  production: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
