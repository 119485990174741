import { Routes } from '@angular/router';
import { LoggedIn } from '@shared/guards/logged-in.guard';

export const AUTH_ROUTES: Routes = [
  {
    path: ':organizacao/auth',
    loadComponent: async () => (await import('./auth.domain')).AuthDomain,
    canActivateChild: [LoggedIn],

    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'sign-up',
      },
      {
        path: 'sign-up',
        loadComponent: async () =>
          (await import('./pages/sign-up/sign-up.page')).SignUpPage,
        canActivate: [LoggedIn],
      },
      {
        path: 'sign-in',
        loadComponent: async () =>
          (await import('./pages/sign-in/sign-in.page')).SignInPage,
        canActivate: [LoggedIn],
      },
    ],
  },
];
