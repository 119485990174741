import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CarsubsAcessosService } from '@app/api/carsubs-acessos/carsubs-acessos.service';

@Component({
  standalone: true,
  selector: 'app-root',
  template: ` <router-outlet></router-outlet> `,
  imports: [RouterModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  constructor(
    private acessoService: CarsubsAcessosService,
    private route: ActivatedRoute,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.acessoService
      .getCarsubsAcessos(location.pathname.split('/')[1])
      .subscribe((acesso: any) => {
        if (sessionStorage.getItem('org')) {
          sessionStorage.removeItem('org');
        }
        if (acesso.data.items.length) {
          if (acesso.data.items[0].Organizacoes.CodigoOrganizacao) {
            sessionStorage.setItem(
              'org',
              acesso.data.items[0].Organizacoes.CodigoOrganizacao
            );
          }
          if (acesso.data.items[0].Organizacoes.CorPortalCarsubs) {
            document.documentElement.style.setProperty(
              '--info-primary',
              `#${acesso.data.items[0].Organizacoes.CorPortalCarsubs}`
            );
            document.documentElement.style.setProperty(
              '--info-orange',
              `#${acesso.data.items[0].Organizacoes.CorPortalCarsubs}`
            );
          } else {
            document.documentElement.style.setProperty(
              '--info-primary',
              `#fab500`
            );
            document.documentElement.style.setProperty(
              '--info-orange',
              `#fab500`
            );
          }
        }
      });

    this.getCodigoSegmentacao();
  }

  public getCodigoSegmentacao() {
    this.route.queryParams.subscribe((params) => {
      const codigoSegmentacao = params['CodigoSegmentacao'];
      const cpf = params['cpf'];
      const cnpj = params['cnpj'];
      const empresa = params['CodigoEmpresa'];
      const motivoPedidoCompra = params['CodigoMotivoCompra'];

      if (codigoSegmentacao) {
        sessionStorage.setItem('segmentation', codigoSegmentacao);
      }
      //remove o codigo segmentação caso na tela de município não exista o parâmetro
      else if (location.pathname.includes('municipio') && !codigoSegmentacao) {
        sessionStorage.removeItem('segmentation');
      }

      if (cpf) {
        sessionStorage.setItem('cpf', cpf);
      } else if (location.pathname.includes('municipio') && !cpf) {
        sessionStorage.removeItem('cpf');
      }

      if (cnpj) {
        sessionStorage.setItem('cnpj', cnpj);
      } else if (location.pathname.includes('municipio') && !cnpj) {
        sessionStorage.removeItem('cnpj');
      }

      if (empresa) {
        sessionStorage.setItem('empresa', empresa);
      } else if (location.pathname.includes('municipio') && !empresa) {
        sessionStorage.removeItem('empresa');
      }

      if (motivoPedidoCompra) {
        sessionStorage.setItem('motivoPedidoCompra', motivoPedidoCompra);
      } else if (location.pathname.includes('municipio') && !empresa) {
        sessionStorage.removeItem('motivoPedidoCompra');
      }

      if (codigoSegmentacao && (cpf || cnpj)) {
        const currentRoute = location.pathname;
        this.router.navigate([
          currentRoute.replace('assinatura/municipio', '/auth/sign-in'),
        ]);
      }
    });
  }
}
