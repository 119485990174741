import { Routes } from '@angular/router';
import { NotOrgComponent } from 'app-not-org.component';
export const APP_ROUTES: Routes = [
  { path: '', component: NotOrgComponent },
  {
    path: ':organizacao',
    pathMatch: 'full',
    redirectTo: ':organizacao/assinatura',
  },
];
