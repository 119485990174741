import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  template: `
    <div
      style="display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100vh;"
    >
      <h1
        style="margin-bottom: 15px; font-size:24px ; font-weight: 500; text-align: center;"
      >
        Portal não localizado.
      </h1>
      <p style=" font-size:18px; text-align: center;">
        Entre em contato com a empresa para obter o link de acesso.
      </p>
    </div>
  `,
  imports: [RouterModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotOrgComponent {}
