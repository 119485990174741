import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import criptografar from '@app/shared/rotinas/criptografar';
import descriptografar from '@app/shared/rotinas/descriptografar';
import jwt from 'jwt-decode';
import { tap } from 'rxjs';
import { BaseApiService } from '../base-api.service';
import { AuthKeys } from './auth.enum';
import {
  Login,
  LoginResponse,
  PostCliente,
  PostClienteResponse,
} from './auth.models';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseApiService {
  currentStorage = sessionStorage;

  constructor(private readonly router: Router) {
    super('clientes');
  }

  register(data: PostCliente) {
    return this.post<PostClienteResponse>(data, 'sign-up').pipe(
      tap((response) => this.setUserData(response))
    );
  }

  login(data: Login) {
    return this.post<LoginResponse>(data, 'sign-in').pipe(
      tap((response) => this.setUserData(response))
    );
  }

  get isLogged(): boolean {
    return typeof this.findInStorageByKey(AuthKeys.INFO_TOKEN) === 'string';
  }

  findInStorageByKey(key: string) {
    this.currentStorage = localStorage.getItem(key)
      ? localStorage
      : sessionStorage;
    return this.currentStorage.getItem(key);
  }

  logout() {
    this.findInStorageByKey(AuthKeys.INFO_TOKEN);
    this.currentStorage.removeItem(AuthKeys.INFO_TOKEN);
    this.currentStorage.removeItem(AuthKeys.INFO_USER);
    const currentRoute = location.pathname;
    const lastTwoRoutes = currentRoute.split('/').slice(-2).join('/');
    this.router.navigate([currentRoute.replace(lastTwoRoutes, 'assinatura')]);
  }

  setUserData(session: any) {
    this.currentStorage.setItem(
      AuthKeys.INFO_TOKEN,
      criptografar(session.data.token)
    );

    const userData: any = jwt(session.data.token);
    this.currentStorage.setItem(
      AuthKeys.INFO_USER,
      criptografar(
        JSON.stringify({
          nome: userData.data.nome,
          codigoCliente: userData.data.user,
          codigoPessoa: userData.data.codigoPessoa,
          dataNascimento: userData.data.dataDeNascimento,
          email: userData.data.email,
          telefone: userData.data.telefone,
        })
      )
    );
  }

  getUserData() {
    const userData = this.findInStorageByKey(AuthKeys.INFO_USER);
    return userData ? JSON.parse(descriptografar(userData)) : null;
  }
}
