import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localePtBr from '@angular/common/locales/pt';
import { LOCALE_ID, enableProdMode, importProvidersFrom } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ASSINATURA_ROUTES } from '@domain/assinatura/assinatura.routing';
import { AUTH_ROUTES } from '@domain/auth/auth.routing';
import { DASHBOARD_ROUTES } from '@domain/dashboard/dashboard.routing';
import { environment } from '@env/environment';
import { AuthorizationInterceptor } from '@interceptors/authorization/authorization.interceptor';
import { HandleErrorInterceptor } from '@interceptors/handle-error/handle-error.interceptor';
import { HotToastModule } from '@ngneat/hot-toast';
import { getPortuguesePaginatorIntl } from '@shared/rotinas/mat-paginator';
import { ENVIRONMENT_TOKEN } from '@shared/tokens/enviroment.token';
import { CarouselComponent } from 'angular-responsive-carousel';
import { AppComponent } from 'app.component';
import { APP_ROUTES } from 'app.routing';
import { NgxMaskModule } from 'ngx-mask';

registerLocaleData(localePtBr);

const ROUTES = [
  ...APP_ROUTES,
  ...ASSINATURA_ROUTES,
  ...AUTH_ROUTES,
  ...DASHBOARD_ROUTES,
];

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      RouterModule.forRoot(ROUTES),
      NgxMaskModule.forRoot(),
      HotToastModule.forRoot(),
      BrowserAnimationsModule,
      HttpClientModule,
      CarouselComponent
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HandleErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
    },
    {
      provide: ENVIRONMENT_TOKEN,
      useValue: environment,
    },
    { provide: LOCALE_ID, useValue: 'pt-BR' },

    { provide: MatPaginatorIntl, useValue: getPortuguesePaginatorIntl() },
  ],
}).catch((err) => console.error(err));
