import { Routes } from '@angular/router';
import { AuthGuard } from '@app/shared/guards/auth.guard';

export const DASHBOARD_ROUTES: Routes = [
  {
    path: ':organizacao/dashboard',
    loadComponent: async () =>
      (await import('./dashboard.domain')).DashboardDomain,
    canActivateChild: [AuthGuard],

    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      {
        path: 'home',
        loadComponent: async () =>
          (await import('./pages/home/home.page')).HomePage,
        canActivate: [AuthGuard],
      },
      {
        path: 'meus-dados',
        loadComponent: async () =>
          (await import('./pages/meus-dados/meus-dados.page')).MeusDadosPage,
        canActivate: [AuthGuard],
      },
      {
        path: 'minhas-assinaturas',
        loadComponent: async () =>
          (await import('./pages/minhas-assinaturas/minhas-assinaturas.page'))
            .MinhasAssinaturasPage,
        canActivate: [AuthGuard],
      },
      {
        path: 'contratos',
        loadComponent: async () =>
          (await import('./pages/contratos/contratos.page')).ContratosPage,
        canActivate: [AuthGuard],
      },
      {
        path: 'pagamentos',
        loadComponent: async () =>
          (await import('./pages/pagamentos/pagamentos.page')).PagamentoPage,
        canActivate: [AuthGuard],
      },
      {
        path: 'notificacoes',
        loadComponent: async () =>
          (await import('./pages/notificacoes/notificacoes.page'))
            .NotificacoesPage,
        canActivate: [AuthGuard],
      },
    ],
  },
];
