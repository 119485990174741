import { Routes } from '@angular/router';
import { OrganizacaoGuard } from '@app/shared/guards/organization.guard';

export const ASSINATURA_ROUTES: Routes = [
  {
    path: ':organizacao/assinatura',
    loadComponent: async () =>
      (await import('./assinatura.domain')).AssinaturaDomain,
    canActivateChild: [OrganizacaoGuard],

    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'municipio',
      },
      {
        path: 'municipio',
        loadComponent: async () =>
          (await import('./pages/steps/1-municipio/municipio.page'))
            .MunicipioPage,
        canActivate: [OrganizacaoGuard],
      },
      {
        path: 'grupo-veiculo',
        loadComponent: async () =>
          (await import('./pages/steps/2-grupo-veiculo/grupo-veiculo.page'))
            .GrupoVeiculoPage,
        canActivate: [OrganizacaoGuard],
      },
      {
        path: 'veiculo',
        loadComponent: async () =>
          (await import('./pages/steps/3-veiculo/veiculo.page')).VeiculoPage,
        canActivate: [OrganizacaoGuard],
      },
      {
        path: 'contrato',
        loadComponent: async () =>
          (await import('./pages/steps/4-contrato/contrato.page')).ContratoPage,
        canActivate: [OrganizacaoGuard],
      },
    ],
  },
];
