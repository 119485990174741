import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/api/auth/auth.service';
import { OrganizacoesService } from '@app/api/organizacoes/organizacoes.service';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

export const LoggedIn = () => {
  const router = inject(Router);
  const service = inject(AuthService);
  const organizacoesService = inject(OrganizacoesService);
  const currentRoute = location.pathname;
  const getBar = currentRoute.split('/');
  const firstBar = getBar[1];
  const rotaAtual = location.pathname.split('/')[1];

  return organizacoesService.getOrganizacao(rotaAtual).pipe(
    map((organizacao) => {
      if (!organizacao) {
        return router.navigate(['/']);
      }
      return service.isLogged
        ? router.navigate([firstBar + '/dashboard'])
        : true;
    }),
    catchError((error) => {
      console.error(error);
      return of(false);
    })
  );
};
