import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthKeys } from '@app/api/auth/auth.enum';
import { AuthService } from '@app/api/auth/auth.service';
import descriptografar from '@app/shared/rotinas/descriptografar';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(private readonly authService: AuthService) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const hasUrlAPI = request.url.includes(environment.BASE_API_URL);

    if (hasUrlAPI) {
      const token = descriptografar(
        this.authService.findInStorageByKey(AuthKeys.INFO_TOKEN) ?? ''
      );
      if (token) {
        const apiToken = { Authorization: `${token}` };
        request = request.clone({ setHeaders: apiToken });
      }
    }
    return next.handle(request);
  }
}
