import { InjectionToken } from '@angular/core';

export interface Options<T> {
  [key: string]: T;
}

export type Environment = Options<any> & {
  production: boolean;
  BASE_API_URL: string;
};

export const ENVIRONMENT_TOKEN = new InjectionToken<Environment>('environment');

export const getLogInterceptorEnvironment = (environment: any) => {
  return [{ provide: ENVIRONMENT_TOKEN, useValue: environment }];
};
