import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '@services/toast/toast.service';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable()
export class HandleErrorInterceptor implements HttpInterceptor {
  constructor(private readonly toastService: ToastService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          this.toastService.showError(error.error.message);
          return throwError(() => error.error.message);
        }

        console.log(error);

        const errorMessage = error?.error?.erros[0].message;

        switch (error.status) {
          case HttpStatusCode.BadRequest: {
            const message =
              errorMessage ??
              'Houve um erro ao realizar a operação. Por favor, tente novamente.';
            this.toastService.showError(message);
            break;
          }
          case HttpStatusCode.Unauthorized: {
            const message =
              errorMessage ??
              'Não autorizado, verifique seus dados para realizar esta operação.';
            this.toastService.showError(message);
            break;
          }
          case HttpStatusCode.Forbidden:
            this.toastService.showError(
              'Você não tem permissão para realizar esta operação.'
            );
            break;
          case HttpStatusCode.NotFound:
            this.toastService.showError(
              'Não foi possível encontrar o recurso solicitado.'
            );
            break;
          case HttpStatusCode.MethodNotAllowed:
            this.toastService.showError('Método de requisição não permitido.');
            break;
          case HttpStatusCode.GatewayTimeout:
            this.toastService.showError(
              'Erro no servidor, tempo de resposta excedido.'
            );
            break;
          default:
            this.toastService.showError(
              errorMessage ??
                'Houve um erro ao realizar a operação. Por favor, tente novamente.'
            );
            break;
        }

        return throwError(() => error);
      })
    );
  }
}
