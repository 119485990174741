import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, inject } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs/internal/Observable';
import { OrganizacoesService } from './organizacoes/organizacoes.service';
const apiToken = new InjectionToken('API_CONTEXT');

@Injectable({
  providedIn: 'root',
})
export class BaseApiService {
  protected readonly http = inject(HttpClient);

  constructor(@Inject(apiToken) private apiContextToken: string) {}

  protected buildUrl(path = '', params = {}): string {
    const getQueryString = (params: { [key: string]: string | string[] }) => {
      return Object.keys(params).map((key) => {
        const value = params[key];
        return `${key}=${value}`;
      });
    };

    const queryString = getQueryString(params);

    const newPath =
      queryString.length > 1
        ? `${path}?${queryString.join('&')}`
        : `${path}?${queryString}`;

    const makeQuery =
      path.length > 0
        ? `${environment.BASE_API_URL}/${this.apiContextToken}/${newPath}`
        : `${environment.BASE_API_URL}/${this.apiContextToken}${newPath}`;

    return makeQuery;
  }

  protected get<T>(
    path?: string,
    params = {},
    columns?: string[],
    adicionarOrg = true
  ): Observable<T> {
    if (adicionarOrg) {
      params = {
        ...params,
        CodigoOrganizacao: sessionStorage.getItem('org'),
      };
    }

    return this.http.get<T>(this.buildUrl(path, params), {
      headers: new HttpHeaders().append('columns', columns ?? []),
    });
  }

  protected post<T>(body: unknown, path?: string, headers?: {}): Observable<T> {
    return this.http.post<T>(this.buildUrl(path), body, headers);
  }

  protected put<T>(body: unknown, path?: string, headers?: {}): Observable<T> {
    return this.http.put<T>(this.buildUrl(path), body, headers);
  }

  protected delete<T>(path?: string, headers?: {}): Observable<T> {
    return this.http.delete<T>(this.buildUrl(path), headers);
  }
}
