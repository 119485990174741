import { Injectable } from '@angular/core';
import { BaseApiService } from '../base-api.service';
import { CarsubsAcessosService } from '../carsubs-acessos/carsubs-acessos.service';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrganizacoesService extends BaseApiService {
  constructor(private carsubsAcessosService: CarsubsAcessosService) {
    super('organizacoes');
  }

  getOrganizacao(rotaAtual: any): Observable<any> {
    return this.carsubsAcessosService.getCarsubsAcessos(rotaAtual).pipe(
      map((acesso: any) => {
        return acesso.data.items[0];
      })
    );
  }
}
